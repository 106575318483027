<template>
  <div class="main-page-wrapper">
    <Header />
    <WebGL />
    <Footer />
  </div>
</template>

<script>
import Header from "../common/Headers/Header.vue";
import WebGL from "./robopark-web-gl.vue";
import Footer from "../Home/Footer.vue";

export default {
  name: "HomeMain",
  components: {
    Header,
    WebGL,
    Footer,
  },
};
</script>
