const ApiRoot =  "https://lew8a84gb3.execute-api.ap-southeast-1.amazonaws.com/beta";

const appConfig = {
    api: {
        login: ApiRoot + "/user/login",
        // logout: ApiRoot + "/auth/logout",
        register: ApiRoot + "/user/signup",
        confirmsignup: ApiRoot + "/testcognitoconfirmsignup"
    }
}

export default appConfig;
