<template>
  <div class="fancy-hero-one">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 col-lg-10 m-auto">
          <h2 class="font-rubik">Get vertify code from email</h2>
        </div>
        <div class="col-lg-9 m-auto">
          <p class="font-rubik">Thank you for choosing RoboPark.</p>
          <p class="font-rubik"></p>
          <p class="font-rubik">Please confirm. <a>Link</a></p>
          <button>abc</button>
        </div>
      </div>
    </div>
    <div class="bubble-one"></div>
    <div class="bubble-two"></div>
    <div class="bubble-three"></div>
    <div class="bubble-four"></div>
    <div class="bubble-five"></div>
    <div class="bubble-six"></div>
  </div>
</template>

<script>
export default {
  name: "FancyHero",
};
</script>
