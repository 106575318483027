<template>
  <div>
    <div
      :class="`${
        isSticky
          ? `theme-main-menu sticky-menu theme-menu-one fixed 
    ${center_white ? 'center-white' : ''}`
          : `theme-main-menu sticky-menu theme-menu-one ${
              center_white ? 'center-white' : ''
            }`
      }`"
    >
      <div
        :class="`d-flex align-items-center ${
          center_white ? 'justify-content-center' : 'justify-content-between'
        }`"
      >
        <div class="logo">
          <routerLink to="/">
            <img
              v-if="!center_white"
              src="../../../assets/images/logo/robopark_icon.png"
              alt=""
            />
            <img
              v-if="center_white"
              src="../../../assets/images/logo/deski_09.svg"
              alt=""
            />
          </routerLink>
        </div>
        <nav id="mega-menu-holder" class="navbar navbar-expand-lg">
          <div class="nav-container">
            <button
              class="navbar-toggler"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
              :class="menuActive ? 'navbar-toggler open' : 'navbar-toggler'"
              @click="menuActive = !menuActive"
            >
              <span></span>
            </button>
            <div class="navbar-collapse collapse" id="collapsibleNavbar">
              <div class="d-lg-flex align-items-center">
                <!-- <button @click="testApi">Test API</button>
                <button @click="getUnity()">Test Unity api</button> -->

                <!-- nav menu start -->
                <NavMenu />
                <!-- nav menu end -->

                <li
                  v-if="isLogin"
                  class="right-button-group profile d-flex align-items-center justify-content-center dropdown dropleft"
                >
                  <img
                    class="profile-image"
                    :src="profileImageUrl"
                    alt="Profile Image"
                  />
                  <ul class="dropdown-menu">
                    <li class="same-style">
                      <router-link to="/profile">
                        <p class="dropdown-item">profile</p>
                      </router-link>
                    </li>
                    <li class="same-style">
                      <p class="dropdown-item" @click="logOut()">Logout</p>
                    </li>
                  </ul>
                </li>

                <ul
                  v-else
                  class="right-button-group d-flex align-items-center justify-content-center"
                >
                  <li>
                    <router-link to="/login" class="signIn-action"
                      >Login</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/register" class="signUp-action"
                      >Get Started</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import testService from "@/services/testApi";
// import { response } from 'express';
import NavMenu from "./NavMenu.vue";

export default {
  name: "HeaderComponent",
  props: { center_white: Boolean },
  components: {
    NavMenu,
  },
  data() {
    return {
      isLogin: false,
      isSticky: false,
      menuActive: false,
      isDropdownOpen: false,
      profileImageUrl:
        "https://i.ibb.co/F3bdBY8/11120574-1068014763209126-4350586015211975163-n.jpg",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleSticky);
    if (localStorage.getItem("token") && localStorage.getItem("user")) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  },
  methods: {
    log() {
      console.log(this.isDropdownOpen);
    },
    logOut() {
      this.isLogin = false;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location = "/";
    },
    handleSticky() {
      if (window.scrollY > 80) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    async testApi() {
      await testService.get();
      alert("respon : ");
    },
    async getUnity() {
      await testService.getUnity();
    },
  },
};
</script>
