<script setup>
import { ref, onMounted } from "vue";
import UnityWebgl from "unity-webgl";
import UnityVue from "unity-webgl/vue";

const unityContainer = ref(null);
const unityContext = new UnityWebgl({
  loaderUrl:
    "https://roboparkgame.s3.ap-southeast-1.amazonaws.com/(06-04)robopark-webgl/Build/(06-04)robopark-webgl.loader.js",
  dataUrl:
    "https://roboparkgame.s3.ap-southeast-1.amazonaws.com/(06-04)robopark-webgl/Build/(06-04)robopark-webgl.data",
  frameworkUrl:
    "https://roboparkgame.s3.ap-southeast-1.amazonaws.com/(06-04)robopark-webgl/Build/(06-04)robopark-webgl.framework.js",
  codeUrl:
    "https://roboparkgame.s3.ap-southeast-1.amazonaws.com/(06-04)robopark-webgl/Build/(06-04)robopark-webgl.wasm",
});

onMounted(() => {
  unityContext.on("device", () => alert("click device ..."));
});

const toggleFullscreen = () => {
  const unityContainerElement = unityContainer.value;
  if (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  ) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  } else {
    if (unityContainerElement.requestFullscreen) {
      unityContainerElement.requestFullscreen().catch((err) => {
        console.error("Error attempting to enable fullscreen:", err);
      });
    } else if (unityContainerElement.webkitRequestFullscreen) {
      unityContainerElement.webkitRequestFullscreen().catch((err) => {
        console.error("Error attempting to enable fullscreen:", err);
      });
    } else if (unityContainerElement.mozRequestFullScreen) {
      unityContainerElement.mozRequestFullScreen().catch((err) => {
        console.error("Error attempting to enable fullscreen:", err);
      });
    } else if (unityContainerElement.msRequestFullscreen) {
      unityContainerElement.msRequestFullscreen().catch((err) => {
        console.error("Error attempting to enable fullscreen:", err);
      });
    }
  }
};
</script>

<template>
  <div>
    <div
      style="
        width: 1200px;
        height: 800px;
        border: 10px solid #428ccf;
        margin: auto;
      "
      ref="unityContainer"
    >
      <UnityVue isFullscreen="true" :unity="unityContext" />
    </div>
    <button
      style="
        width: 300px;
        height: 100px;
        background-color: #428ccf;
        border-radius: 0px 0px 10px 10px;
        color: whitesmoke;
        margin: auto;
        font-size: 36px;
      "
      @click="toggleFullscreen"
    >
      Fullscreen
    </button>
  </div>
</template>
