<template>
  <div class="hero-banner-one">
    <div class="hero-upper-container">
      <!-- <div class="icon-box-one"><img src="../../assets/images/logo/01.png" alt="" /></div> -->
      <!-- <div class="icon-box-two"><img src="../../assets/images/logo/02.png" alt="" /></div>
      <div class="icon-box-three"><img src="../../assets/images/logo/03.png" alt="" /></div>
      <div class="icon-box-four"><img src="../../assets/images/logo/04.png" alt="" /></div>
      <div class="icon-box-five"><img src="../../assets/images/logo/05.png" alt="" /></div>
      <div class="icon-box-six"><img src="../../assets/images/logo/06.png" alt="" /></div>
      <div class="icon-box-seven"><img src="../../assets/images/logo/07.png" alt="" /></div>
      <div class="icon-box-eight"><img src="../../assets/images/logo/08.png" alt="" /></div> -->

      <div class="bubble-one"></div>
      <div class="bubble-two"></div>
      <div class="bubble-three"></div>
      <div class="bubble-four"></div>
      <div class="bubble-five"></div>
      <div class="bubble-six"></div>
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 m-auto">
            <h1 class="font-rubik hero-heading">
              <span
                ><p class="y-text">FUN FOR</p>
                FUTURE
              </span>
            </h1>
            <p class="hero-sub-heading">
              Robotics learning platform with simulation online
            </p>
          </div>
        </div>
        <form @submit.prevent="onSubmit" class="subscription-form">
          <input
            required
            type="email"
            v-model="email"
            placeholder="Your email"
          />
          <button>
            <p style="margin-right: 3px; font-size: larger">
              <strong>Get Deals!</strong>
            </p>
          </button>
        </form>
        <!-- <p class="sing-in-call">
          Already using robopark?
          <router-link to="/login">Sign in</router-link>.
        </p> -->
      </div>
    </div>
    <!-- /.hero-upper-container -->
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "HeroArea",
  data() {
    return {
      email: null,
    };
  },
  methods: {
    onSubmit() {
      var webAppUrl =
        "https://script.google.com/macros/s/AKfycbzD9L8BA2qvQt8TJs7aHGpdv5FQj0_7viPSJ7huye4txIKdEv54BPC85LIlr4vB9ivi/exec";

      // Send the email to the Google Apps Script
      fetch(webAppUrl + "?email=" + encodeURIComponent(this.email), {
        method: "GET",
      })
        .then(function (response) {
          // Handle response if needed
          console.log(response);
          if (response.status == 200) {
            Swal.fire({
              icon: "success",
              title: "Thank you!",
              text: `Submit successfully for email:${this.email}`,
              showConfirmButton: true,
            });
            this.email = null;
          }
        })
        .catch((error) => {
          // do something with error
          console.log(error);
          Swal.fire({
            icon: "success",
            title: "Thank you!",
            text: `Submit successfully for email:${this.email}`,
            showConfirmButton: true,
          });
          this.email = null;
          // Swal.fire({
          //   icon: "error",
          //   title: "Something Wrong!",
          //   text: error,
          //   showConfirmButton: true,
          // });
        });
    },
  },
};
</script>
