<template>
  <div class="user-data-page clearfix d-lg-flex">
    <div
      class="illustration-wrapper d-flex align-items-center justify-content-between flex-column"
    >
      <h3 class="font-rubik">
        Want your best managment <br />software?
        <router-link to="/register">sign up</router-link>
      </h3>
      <div class="illustration-holder">
        <img
          src="../../assets/images/assets/ils_08.svg"
          alt=""
          class="illustration"
        />
        <img
          src="../../assets/images/assets/ils_08.1.svg"
          alt=""
          class="shapes shape-one"
        />
        <img
          src="../../assets/images/assets/ils_08.2.svg"
          alt=""
          class="shapes shape-two"
        />
      </div>
    </div>
    <!-- /.illustration-wrapper -->

    <div class="form-wrapper">
      <div class="d-flex justify-content-between">
        <div class="logo">
          <router-link to="/">
            <img src="../../assets/images/logo/robopark_icon.png" alt="" />
          </router-link>
        </div>
        <router-link to="/" class="font-rubik go-back-button"
          >Go to home</router-link
        >
      </div>
      <form @submit.prevent="onSubmit()" class="user-data-form mt-80 md-mt-40">
        <h2>
          Hi buddy, welcome <br />
          Back!
        </h2>
        <p class="header-info pt-30 pb-50">
          Still don't have an account?
          <router-link to="/register">Sign up</router-link>
        </p>

        <div class="row">
          <div class="col-12">
            <div class="input-group-meta mb-80 sm-mb-70">
              <label>Email</label>
              <input
                v-model="loginInformation.username"
                required
                type="email"
                placeholder="bolcovfed@ce.edu"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="input-group-meta mb-15">
              <label>Password</label>
              <input
                v-model="loginInformation.password"
                required
                :type="hidePassword ? 'text' : 'password'"
                placeholder="Enter Password"
                class="pass_log_id"
              />
              <span class="placeholder_icon">
                <span
                  :class="`passVicon ${hidePassword ? 'hide-pass' : ''}`"
                  @click="handleHidePassword"
                >
                  <img src="../../assets/images/icon/view.svg" alt="" />
                </span>
              </span>
            </div>
          </div>
          <div class="col-12">
            <div
              class="agreement-checkbox d-flex justify-content-between align-items-center"
            >
              <div>
                <input v-model="remember" type="checkbox" id="remember" />
                <label for="remember">Keep me logged in</label>
              </div>
              <a href="#">Forget Password?</a>
            </div>
            <!-- /.agreement-checkbox -->
          </div>
          <div class="col-12">
            <button type="submit" class="theme-btn-one mt-50 mb-50">
              Login
            </button>
          </div>

          <!-- <div class="col-6">
            <button @click="googleLoginHandler" class="theme-btn-two mt-50 mb-50">Login with google</button>
          </div>
          <div class="col-6">
            <button @click="facebookLoginHandler" class="theme-btn-five mt-50 mb-50">Login with facebook</button>
          </div> -->
        </div>
      </form>
      <div class="row">
        <div class="col-4"></div>

        <div class="col-6">
          <!-- <v-facebook-login app-id="727182452409347"></v-facebook-login> -->
          <facebook-login
            :app-id="yourAppId"
            @success="onFacebookLoginSuccess"
            @failure="onFacebookLoginFailure"
          >
            <button class="btn btn-facebook mt-50 mb-50">
              <i class="fa fa-facebook"></i>

              Login with Facebook
            </button>
          </facebook-login>
          <!-- <button
            @click="loginWithFacebook"
            class="btn btn-facebook mt-50 mb-50"
          >
            <i class="fa fa-facebook"></i>
            Login with Facebook
          </button> -->
        </div>
      </div>
      <div class="col-12">
        <p class="text-center font-rubik copyright-text">
          © Copyright 2021 deski
        </p>
      </div>
    </div>
    <!-- /.form-wrapper -->
  </div>
</template>

<script>
import authService from "@/services/auth";
import Swal from "sweetalert2";
// import VFacebookLogin from "vue-facebook-login-component-next";
import FacebookLogin from "vue-facebook-login-component-next";

export default {
  // components: { VFacebookLogin },
  components: { FacebookLogin },
  name: "LoginArea",
  data() {
    return {
      remember: false,
      hidePassword: false,
      loginInformation: {
        username: "",
        password: "",
      },
      yourAppId:
        "                                                                ",
    };
  },
  mounted() {
    if (localStorage.getItem("loginUser")) {
      this.remember = true;
      this.loginInformation.username = localStorage.getItem("loginUser");
    }
  },

  methods: {
    onFacebookLoginSuccess(response) {
      // handle successful login
      console.log(response);
    },
    onFacebookLoginFailure(response) {
      // handle failed login
      console.log(response);
    },
    setTokenSim() {
      if (this.remember) {
        localStorage.setItem("loginUser", this.loginInformation.username);
      }
      localStorage.setItem("user", JSON.stringify("testUser"));
      localStorage.setItem("token", JSON.stringify("eiei"));
    },
    onSubmit() {
      this.login();
    },
    handleHidePassword() {
      this.hidePassword = !this.hidePassword;
    },
    async login() {
      let resp = await authService.login(this.loginInformation);
      if (resp.data.success == true) {
        // window.location = "/";
        Swal.fire({
          icon: "success",
          title: "Login successfully",
          showConfirmButton: false,
        })
          .then(this.setTokenSim())
          .then(this.$router.push("/"));
      } else {
        Swal.fire({
          title: resp.data.message,
          // text: resp.data.message,
          icon: "error",
        });
      }
    },
  },
};
</script>
