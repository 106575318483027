<template>
  <HomeMain/>
</template>

<script>
import HomeMain from '../components/Home';

export default {
  name:'HomePage',
  components:{
    HomeMain
  }
}

</script>