<template>
  <div class="block-style-thirtySix lg-container mt-225 md-pt-120" id="feature">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-7 order-lg-last" data-aos="fade-left">
          <div class="ps-lg-5">
            <div class="title-style-fourteen">
              <div class="upper-title">Made for human</div>
              <h2>Quality, actionable data process.</h2>
            </div>
            <div class="tag-line">
              Creating an online survey has never been easier. With many
              advanced features of deski.
            </div>
            <ul class="list-item">
              <li>Easy-to-use survey maker interface</li>
              <li>Drag and Drop to change the order of questions</li>
              <li>Quota management</li>
              <li>
                Step view layout for interactive survey filling experiences
              </li>
              <li>Customizable URL</li>
            </ul>
          </div>
        </div>
        <div
          class="col-xl-6 col-lg-5 text-center text-lg-start order-lg-first"
          data-aos="fade-right"
        >
          <div class="illustration-holder d-inline-block md-mt-60">
            <img
              src="../../assets/images/assets/ils_23.svg"
              alt=""
              class="w-100"
            />
            <img
              src="../../assets/images/assets/ils_23.1.svg"
              alt=""
              class="shapes shape-one"
            />
            <img
              src="../../assets/images/assets/ils_23.2.svg"
              alt=""
              class="shapes shape-two"
            />
            <img
              src="../../assets/images/assets/ils_23.3.svg"
              alt=""
              class="shapes shape-three"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockStyle",
};
</script>
