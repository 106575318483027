<template>
  <div class="fancy-feature-one pb-150 md-pb-80">
    <div class="text-center">
      <div class="feature-img-area">
        <!-- <img src="../../assets/images/assets/home-img-1035x552.png" alt="" /> -->
        <!-- <iframe width="1060" height="615" src="https://youtu.be/embed/-p8foJfEPlI" title="Robopark Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
        <!-- <iframe width="1060" height="615" src="https://www.youtube.com/embed/-p8foJfEPlI?autoplay=1" title="YouTube video player" frameborder="0" allow="autoplay" allowfullscreen></iframe> -->
        <video
          width="1060"
          height="615"
          autoplay
          muted
          src="../../assets/video/home.mp4"
          type="video/mp4"
        ></video>
        <img
          src="../../assets/images/assets/character591x591.png"
          alt=""
          class="cs-screen screen-one"
          style="height: auto"
        />
        <!-- feature-img-01-01.png -->
        <!-- <img src="../../assets/images/assets/feature-img-01-02.png" alt="" class="cs-screen screen-two"
          data-aos="fade-left" data-aos-duration="1200" />
        <img src="../../assets/images/assets/feature-img-01-03.png" alt="" class="cs-screen screen-three"
          data-aos="fade-right" data-aos-duration="1200" data-aos-delay="100" />
        <img src="../../assets/images/assets/feature-img-01-04.png" alt="" class="cs-screen screen-four"
          data-aos="fade-right" data-aos-duration="1200" data-aos-delay="150" />
        <img src="../../assets/images/assets/feature-img-01-05.png" alt="" class="cs-screen screen-five"
          data-aos="fade-left" data-aos-duration="1200" data-aos-delay="200" /> -->
      </div>
      <!-- /.feature-img-area -->
    </div>
    <!-- /.text-center -->

    <div class="block-style-one" style="margin-top: 70px">
      <div class="container">
        <div
          class="d-lg-flex align-items-center justify-content-between inner-container"
        >
          <div
            v-for="item in featureData"
            :key="item.id"
            class="block-meta-data text-center"
          >
            <div class="line-dot-container">
              <div class="icon-box">
                <img :src="item.icon" alt="" />
              </div>
              <!-- /.icon-box -->
              <p>
                <strong>{{ item.title }}</strong>
              </p>
            </div>
            <div :class="item.textAlign ? item.textAlign : 'hover-content'">
              <span>{{ item.desc }}</span>
            </div>
          </div>
          <!-- /.block-meta-data -->
        </div>
      </div>
      <!-- /.container -->
    </div>
    <!-- /.block-style-one -->
  </div>
</template>

<script>
export default {
  name: "featureOne",
  data() {
    return {
      featureData: [
        {
          id: 1,
          icon: require(`@/assets/images/icon/33-change-color.svg`),
          title: "Anytime, Anywhere",
          desc: "Explore robotics at your pace, no matter your location. Learn on your schedule, whenever inspiration strikes.",
        },
        {
          id: 2,
          icon: require(`@/assets/images/icon/07.svg`),
          title: "Fun Challenges with Friends",
          desc: "Elevate your learning experience by competing in exciting robot challenges alongside friends.",
        },
        // {
        //   id: 3,
        //   icon: require(`@/assets/images/icon/03.svg`),
        //   title: 'Create Wireframe',
        //   desc: "You are one step ahead to create wireframe. Let Automation do it for you. Simplify workflows.",
        //   textAlign: 'hover-content text-center'
        // },
        {
          id: 3,
          icon: require(`@/assets/images/icon/13.svg`),
          title: "One stop service",
          desc: "Empower educators and parents with tools that guide kids toward the next level of achievement, all in one comprehensive platform.",
          textAlign: "hover-content text-end",
        },
      ],
    };
  },
};
</script>
