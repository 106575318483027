<template>
  <div class="main-page-wrapper p0 overflow-hidden">
    <LoginArea />
  </div>
</template>

<script>
import LoginArea from "./login-area.vue";

export default {
  name: "LoginAreaMain",
  components: { LoginArea },
};
</script>
