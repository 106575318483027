import axios from 'axios';
import appConfig from '../config';

const authService = {
    create: async (newuser) => {
        return await axios.post(appConfig.api.register, newuser);
    }
    ,
    login: async (information) => {
        return await axios.post(appConfig.api.login,information );
    }
    
    ,
    logout() {
        // return Axios.post(appConfig.api.logout)
        //     .then(response => {
                localStorage.removeItem('user');
                localStorage.removeItem('permissions');
                localStorage.removeItem('token');
                // return response
            // })
            // .catch(error => {
            //     localStorage.removeItem('user');
            //     localStorage.removeItem('permissions');
            //     localStorage.removeItem('token');
            //     throw error
            // })
    },
    getAuthHeader() {
        let token = JSON.parse(localStorage.getItem('token'))
        if (token) {
            return 'Bearer ' + token;
        } else {
            return ''
        }
    }
};


export default authService;