<template>
  <div class="fancy-feature-seven">
    <div class="container">
      <div class="title-style-two text-center mb-150 md-mb-70">
        <p>Our Product</p>
        <h2>
          Know everything about
          <span
            >RoboPark
            <img src="../../assets/images/shape/line-shape-8.svg" alt=""
          /></span>
          feature.
        </h2>
      </div>
      <!-- /.title-style-two -->

      <div class="block-wrapper">
        <div
          v-for="item in feature_data"
          :key="item.id"
          class="block-style-nine"
        >
          <div class="row align-items-center">
            <div
              :class="`col-lg-7 col-md-9 m-auto ${
                item.order_1 ? item.order_1 : ''
              }`"
              :data-aos="item.fade_1"
              data-aos-duration="1200"
            >
              <div class="illustration-holder">
                <img
                  v-if="item.position != 'right'"
                  style="height: 300px; margin: auto"
                  :src="item.img"
                  alt=""
                />
                <img
                  v-else
                  style="height: 300px; margin: auto"
                  :src="item.img"
                  alt=""
                />
              </div>
              <!-- /.illustration-holder -->
            </div>
            <div
              :class="`col-lg-5 ${item.order_2 ? item.order_2 : ''}`"
              :data-aos="item.fade_2"
              data-aos-duration="1200"
            >
              <div class="text-wrapper">
                <h6>{{ item.sm_title }}</h6>
                <h3 class="title font-gilroy-bold">{{ item.title }}</h3>
                <p class="font-rubik">{{ item.subtitle }}</p>
              </div>
              <!-- /.text-wrapper -->
            </div>
          </div>
          <div style="margin-bottom: 100px"></div>
        </div>
        <!-- /.block-style-nine -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FancyFeature",
  data() {
    return {
      feature_data: [
        {
          id: 1,
          fade_1: "fade-right",
          fade_2: "fade-left",
          img: require(`@/assets/images/assets/Group 255.png`),
          sm_title: "Learning",
          title: "Elevate Your Learning",
          subtitle:
            "Embark on a journey of knowledge with our diverse robotics courses, designed for learners of all levels. From basics to advanced concepts, empower yourself with hands-on education that sparks curiosity and innovation.",
        },
        {
          id: 2,
          fade_1: "fade-left",
          fade_2: "fade-right",
          order_1: "order-lg-last",
          order_2: "order-lg-first",
          img: require(`@/assets/images/assets/Group 254.png`),
          sm_title: "Competition",
          title: "Thrilling Competitions Await",
          subtitle:
            "Step into the arena of friendly competition, where robots battle for glory. Join forces with fellow enthusiasts and put your skills to the test in exhilarating challenges that showcase creativity and strategic thinking.",
          position: "right",
        },
        {
          id: 3,
          fade_1: "fade-right",
          fade_2: "fade-left",
          img: require(`@/assets/images/assets/unnamed.png`),
          sm_title: "Maker",
          title: "Ignite the Maker Spirit",
          subtitle:
            'Unleash your creativity in the "Maker" zone, where you bring your robot designs to life. From concept to creation, our platform offers the tools and resources you need to craft, tinker, and innovate, turning your ideas into tangible robotics marvels.',
        },
        {
          id: 4,
          fade_1: "fade-left",
          fade_2: "fade-right",
          order_1: "order-lg-last",
          order_2: "order-lg-first",
          img: require(`@/assets/images/assets/Group 256.png`),
          sm_title: "Dashboard",
          title: "Insights at Your Fingertips",
          subtitle:
            'Empower educators, parents, and students with a comprehensive "Dashboard" that tracks progress, assesses performance, and provides valuable insights. Stay informed, make informed decisions, and watch as your robotics journey reaches new heights.',
          position: "right",
        },
      ],
    };
  },
};
</script>
