<template>
    <div>
        <SpinnerLoading/>
    </div>
</template>

<script>

import SpinnerLoading from './spinner.vue';

export default { 
  name:"mazeGame",
  components:{SpinnerLoading}
}
</script>
