<template>
  <div class="main-page-wrapper">
    <Header />

    <div class="row">
      <div class="col-lg-2 col-md-6">
        <div class="profile-sidebar">
          <div class="radio-inputs">
            <label class="radio" v-for="option in options" :key="option.value">
              <input
                type="radio"
                :name="radioName"
                :value="option.value"
                v-model="selectedOption"
              />
              <span class="name">{{ option.label }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-6" v-if="selectedOption === 'profile'">
        <div class="profile-dashboard">
          <div class="justify-content-center">
            <h3
              style="
                color: white;
                text-decoration: underline;
                width: fit-content;
                margin: auto;
              "
            >
              Profile
            </h3>
            <img
              class="profile-image"
              :src="profileImageUrl"
              alt="Profile Image"
              style="width: 160px; margin: auto; padding-top: 20px"
            />
          </div>
          <div class="profile-dashboard-box">
            <div class="profile-text">
              <p>Username:</p>
              <p>UserId:</p>
              <p>Parent:</p>
              <p></p>
              <p>Name:</p>
              <p>Age:</p>
              <p>School:</p>
            </div>
          </div>
        </div>
        <div class="statistic-dashboard">
          <h3
            style="
              color: white;
              text-decoration: underline;
              width: fit-content;
              margin: auto;
            "
          >
            Statistic
          </h3>
          <div class="row">
            <div class="col-6">abc</div>
            <div class="col-6">abc</div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12" v-if="selectedOption === 'profile'">
        <div class="contact-dashboard">
          <h3
            style="
              color: white;
              text-decoration: underline;
              width: fit-content;
              margin: auto;
            "
          >
            contact
          </h3>
        </div>
        <div class="talent-dashboard">
          <h3
            style="
              color: white;
              text-decoration: underline;
              width: fit-content;
              margin: auto;
            "
          >
            talent
          </h3>
        </div>
      </div>

      <div class="col-lg-10 col-md-12" v-else>
        <div class="collection-dashboard col-6">
          <h3
            style="
              color: white;
              text-decoration: underline;
              width: fit-content;
              margin: auto;
            "
          >
            collection
          </h3>
        </div>
        <div class="badge-dashboard col-6">
          <h3
            style="
              color: white;
              text-decoration: underline;
              width: fit-content;
              margin: auto;
            "
          >
            badge
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../common/Headers/Header.vue";

export default {
  name: "dashboardComponent",
  components: {
    Header,
  },
  data() {
    return {
      options: [
        { value: "profile", label: "Profile" },
        { value: "dashboard", label: "Dashboard" },
      ],
      selectedOption: "profile",
      isDropdownOpen: false,
      profileImageUrl:
        "https://i.ibb.co/F3bdBY8/11120574-1068014763209126-4350586015211975163-n.jpg",
    };
  },
  computed: {
    radioName() {
      return "radio"; // Set the name attribute for radio inputs
    },
  },
};
</script>
