import axios from 'axios';
// import appConfig from '../config';

const testService = {
    get: async () => {
        return await axios.post("https://lew8a84gb3.execute-api.ap-southeast-1.amazonaws.com/beta/user/forgot-password",{
            "username":"signup_test@test.com",
                });
    },
    getUnity: async () => {
        return await axios.post('https://eypyb3grlc.execute-api.ap-southeast-1.amazonaws.com/Default/unity-test',{
            "userUid":1,
            "rank":"goderic",
            "userName":"Billadin"
        })
    }
};


export default testService;