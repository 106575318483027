<template>
  <div class="user-data-page clearfix d-lg-flex">
    <div
      class="illustration-wrapper d-flex align-items-center justify-content-between flex-column"
    >
      <h3 class="font-rubik">
        We have a “strategic” plan its <br />
        called doing things.
      </h3>
      <div class="illustration-holder">
        <img
          src="../../assets/images/assets/ils_08.svg"
          alt=""
          class="illustration"
        />
        <img
          src="../../assets/images/assets/ils_08.1.svg"
          alt=""
          class="shapes shape-one"
        />
        <img
          src="../../assets/images/assets/ils_08.2.svg"
          alt=""
          class="shapes shape-two"
        />
      </div>
    </div>
    <!-- /.illustration-wrapper -->

    <div class="form-wrapper">
      <div class="d-flex justify-content-between">
        <div class="logo">
          <router-link to="/">
            <img src="../../assets/images/logo/robopark_icon.png" alt="" />
          </router-link>
        </div>
        <router-link to="/" class="font-rubik go-back-button"
          >Go to home</router-link
        >
      </div>

      <form @submit.prevent="onSubmit()" class="user-data-form mt-30">
        <h2>Join with thousands of startup!</h2>
        <p class="header-info pt-30 pb-50">
          Already have an account?
          <router-link to="/login">Login</router-link>
        </p>

        <div class="row">
          <div class="col-12">
            <div class="input-group-meta mb-50">
              <label>Full Name</label>
              <input
                v-model="registerInformation.UserAttributes.name"
                required
                type="text"
                placeholder="Melvin Carlson"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="input-group-meta mb-50">
              <label>Email</label>
              <input
                v-model="registerInformation.email"
                required
                type="email"
                placeholder="bolcovfed@ce.edu"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="input-group-meta mb-50">
              <label>Username</label>
              <input
                v-model="registerInformation.username"
                required
                type="text"
                placeholder="bolcovfed"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="input-group-meta">
              <label>Password</label>
              <input
                v-model="registerInformation.password"
                required
                :type="hidePassword ? 'text' : 'password'"
                pattern="[A-Za-z0-9]+"
                placeholder="Enter Password"
                class="pass_log_id"
              />
              <span class="placeholder_icon">
                <span
                  :class="`passVicon ${hidePassword ? 'hide-pass' : ''}`"
                  @click="handleHidePassword"
                >
                  <img src="../../assets/images/icon/view.svg" alt="" />
                </span>
              </span>
            </div>
            <p class="mb-3">
              Password should only contain letters and numbers A-Za-z0-9
            </p>
          </div>
          <div class="col-12">
            <div class="input-group-meta mb-50">
              <label>Re-type Password</label>
              <input
                required
                :type="hideConfirmPassword ? 'text' : 'password'"
                placeholder="Enter Password"
                pattern="[A-Za-z0-9]+"
                class="pass_log_id"
              />
              <span class="placeholder_icon">
                <span
                  :class="`passVicon ${hideConfirmPassword ? 'hide-pass' : ''}`"
                  @click="handleHideConfirmPassword"
                >
                  <img src="../../assets/images/icon/view.svg" alt="" />
                </span>
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="input-group-meta mb-50">
              <label>Phone number</label>
              <input
                v-model="registerInformation.UserAttributes.phone_number"
                required
                type="string"
                placeholder="xxx-xxx-xxxx"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="input-group-meta mb-50" for="birthday">
              <label>Birthday</label>
              <input
                id="birthday"
                v-model="registerInformation.UserAttributes.birthdate"
                required
                type="date"
              />
            </div>
          </div>

          <label>Gender</label>
          <div class="d-flex mb-3">
            <section class="col-6">
              <input
                v-model="registerInformation.UserAttributes.gender"
                value="male"
                type="radio"
                name="gender"
                id="male"
              />
              <label for="male">Male</label>
            </section>
            <section class="col-6">
              <input
                v-model="registerInformation.UserAttributes.gender"
                value="female"
                type="radio"
                name="styles"
                id="female"
              />
              <label for="female">Female</label>
            </section>
          </div>

          <!-- /.agreement-checkbox -->

          <div class="col-12">
            <div
              class="agreement-checkbox d-flex justify-content-between align-items-center sm-mt-10"
            >
              <div>
                <input required type="checkbox" id="agree_to_policy" />
                <label for="agree_to_policy"
                  >By clicking "SIGN UP" I agree to the Terms and Conditions and
                  Privacy Policy.</label
                >
              </div>
            </div>
            <!-- /.agreement-checkbox -->
          </div>

          <div class="col-12">
            <label v-show="status == true"> User created!</label>
            <label v-show="status == false">{{ errorMessage }}</label>
          </div>
          <div class="col-12">
            <button class="theme-btn-one mt-30 mb-50">Sign Up</button>
          </div>
          <div class="col-12">
            <p class="text-center font-rubik copyright-text">
              © Copyright 2021 deski
            </p>
          </div>
        </div>
      </form>
    </div>
    <!-- /.form-wrapper -->
  </div>
</template>

<script>
import registerService from "../../services/auth";
import Swal from "sweetalert2";

export default {
  name: "SignUpArea",
  data() {
    return {
      hidePassword: false,
      hideConfirmPassword: false,
      status: "",
      errorMessage: "",
      registerInformation: {
        username: "",
        password: "",
        UserAttributes: {
          name: "",
          gender: "",
          birthdate: "",
          phone_number: "",
        },
      },
    };
  },
  methods: {
    onSubmit() {
      this.register();
    },
    handleHidePassword() {
      this.hidePassword = !this.hidePassword;
    },
    handleHideConfirmPassword() {
      this.hideConfirmPassword = !this.hideConfirmPassword;
    },
    async register() {
      let phoneNumber = this.registerInformation.UserAttributes.phone_number
        .slice(1)
        .replace(/-/gi, "");
      const date = new Date(this.registerInformation.UserAttributes.birthdate);
      // console.log(date);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      let dateFormat = month + "/" + day + "/" + year;
      let data = {
        username: this.registerInformation.username,
        email: this.registerInformation.email,
        password: this.registerInformation.password,
        name: this.registerInformation.UserAttributes.name,
        gender: this.registerInformation.UserAttributes.gender,
        birthdate: dateFormat,
        phone_number: phoneNumber,
      };
      // console.log(dateFormat);
      let resp = await registerService.create(data);
      if (resp.data.success == true) {
        // window.location = "/";
        Swal.fire({
          icon: "success",
          title: "Register successfully please confirm e-mail",
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push("/login");
          }
        });
      } else {
        Swal.fire({
          title: resp.data.message,
          // text: resp.data.message,
          icon: "error",
        });
      }
    },
  },
};
</script>
